import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 50;
`;

export const Container = styled.div`
  width: 530px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 16px;
`;

export const Header = styled.header`
  padding: 16px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const DownloadFiles = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ListFiles = styled.ul`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  max-height: 296px;
  height: 296px;
  overflow-y: auto;
  gap: 8px;
`;

export const File = styled.li`
  border: 1px solid #dedede;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Footer = styled.footer`
  padding: 16px 24px;
  display: flex;
  border-top: 1px solid #dedede;
  justify-content: flex-end;
`;
