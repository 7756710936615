import { format } from 'date-fns';
import { SectionContainer } from '../styles';
import {
  CheckboxRow,
  ChipsContainer,
  Data,
  DataContainer,
  FilesContainer,
  InfoCardContainer,
  PatientInfoContainer,
  ScrollChipsContainer,
  SectionInfoContainer,
  StatusInfoContainer,
} from './styles';

import {
  Checkbox,
  Chips,
  IconButton,
  Tags,
  Textarea,
  Typography,
} from '@wisecare-tech/design-system-web';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { GetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { FileCard } from './FileCard';

export const ReturnHistory: React.FC = () => {
  const [chipSelected, setChipSelected] = useState<
    GetDiagnosisReportByID.Model['pendencies'][0] | undefined
  >(undefined);

  const handleScroll = (scrollOffset: number) => {
    const container = document.getElementById('scroll-chips-container');
    if (container) {
      container.scrollLeft += scrollOffset;
    }
  };

  const { diagnosisData } = useGetDiagnosisContext();
  const pendencies = diagnosisData?.pendencies;

  useEffect(() => {
    setChipSelected(pendencies?.[0]);
  }, [diagnosisData]);

  return (
    <SectionContainer style={{ paddingBottom: 0 }}>
      <ChipsContainer>
        <IconButton
          variant="primary"
          size="sm"
          onClick={() => handleScroll(-500)}
          icon="chevron_left"
        />
        <ScrollChipsContainer id="scroll-chips-container">
          {pendencies?.map(item => (
            <Chips
              key={item.created}
              onClick={() => setChipSelected(item)}
              variant={
                item.created === chipSelected?.created ? 'blue' : 'white'
              }
              text={format(new Date(item.created), "dd/MM/yyyy 'às' HH:mm", {
                locale: ptBR,
              })}
            />
          ))}
        </ScrollChipsContainer>
        <IconButton
          variant="primary"
          size="sm"
          onClick={() => handleScroll(500)}
          icon="chevron_right"
        />
      </ChipsContainer>
      <SectionInfoContainer>
        <InfoCardContainer>
          <DataContainer>
            <Data>
              <Typography variant="b4_14regular" className="tw-black-4">
                Solicitante:
              </Typography>
              <Typography variant="b3_14medium">
                {diagnosisData?.requester.fullname}
              </Typography>
            </Data>
            <Data>
              <Typography variant="b4_14regular" className="tw-black-4">
                Profissional laudista:
              </Typography>
              <Typography variant="b3_14medium">
                {diagnosisData?.specialist?.fullname ?? '--'}
              </Typography>
            </Data>
          </DataContainer>
          <StatusInfoContainer>
            <Tags
              text={chipSelected?.solved ? 'Resolvido' : 'Pendente'}
              variant={chipSelected?.solved ? 'green' : 'red'}
            />
          </StatusInfoContainer>
        </InfoCardContainer>
      </SectionInfoContainer>
      <SectionInfoContainer>
        <Typography variant="b2_14semibold">Informações do paciente</Typography>
        <PatientInfoContainer>
          <CheckboxRow>
            <Checkbox disabled checked={chipSelected?.problemCon}>
              Ausência ou inconsistência nos dados do paciente
            </Checkbox>
            <Checkbox disabled checked={chipSelected?.problemDescr}>
              Problemas no subjetivo e objetivo
            </Checkbox>
          </CheckboxRow>
          <Textarea disabled value={chipSelected?.description} />
        </PatientInfoContainer>
      </SectionInfoContainer>
      {chipSelected?.files && (
        <SectionInfoContainer>
          <Typography variant="b2_14semibold">Arquivos</Typography>
          <FilesContainer>
            <FileCard />
            <FileCard />
          </FilesContainer>
        </SectionInfoContainer>
      )}
    </SectionContainer>
  );
};
