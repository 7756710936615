import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 90%;

  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  gap: 1rem;
`;

export const Box = styled.div`
  margin: 1.5rem 0;
  padding: 0 3.125rem;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
`;

export const TitleText = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
