import { ColumnDef } from '@tanstack/react-table';
import {
  IconButton,
  SortButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { IconFlag } from '~/presentation/base/icons';
import { priorityMap } from '~/utils/mapPriority';
import { calculateAgeLaudo } from '~/utils/tableDateLaudo';
import { Information, Priority } from './styles';
import {
  iOnCallRequest,
  iOnCallRequestRecords,
} from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { History } from '~/main/routes';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const columns_finalized: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as iOnCallRequest['records'][0]['consultant'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(data?.birthdate)}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'onCall',
    header: ({ column }) => {
      return <SortButton column={column}>Especialidade</SortButton>;
    },
    cell: ({ row }) => {
      const onCall = row.getValue(
        'onCall',
      ) as iOnCallRequest['records'][0]['onCall'];

      return <Typography variant="b4_14regular">{onCall.name}</Typography>;
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => (
      <SortButton column={column}>Última atualização</SortButton>
    ),
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as iOnCallRequest['records'][0]['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    accessorKey: 'updated',
    header: ({ column }) => (
      <SortButton column={column}>Última atualização</SortButton>
    ),
    cell: ({ row }) => {
      const updated = row.getValue(
        'updated',
      ) as iOnCallRequest['records'][0]['updated'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(updated)?.date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(updated)?.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      const store: iStore = storeDev.getState();

      const url = window.location.pathname;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <IconButton
            icon="visibility"
            variant="primary"
            size="sm"
            onClick={() => {
              History.getHistory().push('/appointment/detail', {
                appointmentId: value?.currentAppointment?.id,
                from: url.replaceAll('/', ''),
              });
            }}
          />
          <IconButton
            icon="refresh"
            variant="primary"
            size="sm"
            onClick={() => {
              makeReduxActiveMessage().active({
                active: 'twoButtonModal',
                actionCancel: () => {
                  closeModal();
                },
                data: {
                  variant: 'INFO',
                  message:
                    'Sua solicitação foi cancelada pelo profissional e agora você está reenviando para um novo atendimento. Os dados enviados pela primeira vez ficarão o mesmo. Tem certeza que deseja continuar?',
                  textSecondaryButton: 'Cancelar',
                  textPrimaryButton: 'Sim, quero reenviar',
                  title: 'Reenviar solicitação',
                },
                actionOk: () => {
                  closeModal();
                },
              });
            }}
          />
        </div>
      );
    },
  },
];
