import React, { useCallback } from 'react';
import { Action, Container, Item, List, Actions, Info } from './styles';
import { Button, Input } from '~/presentation/components/UI';
import { IconSearch } from '~/presentation/base/icons';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import saveAs from 'file-saver';
import { iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';
import { Icon } from '@wisecare-tech/design-system-web';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

interface ownProps {
  reportId: number;
  files?: ListDiagnosisReport.Model['records'][0]['files'];
}

const ListDiagnosisFiles: React.FC<ownProps> = ({ reportId, files }) => {
  console.log('<<< files >>>', files);

  const listFiles = useCallback(() => {}, [files]);
  const { token } = useSelector((store: iStore) => store.auth.access);

  return (
    <Container>
      <div className="info-row">
        <div>{`${files?.length} Arquivo${files?.length !== 1 ? 's' : ''}`}</div>
        <Button size="medium">Baixar arquivos</Button>
      </div>
      <Input placeholder="Buscar arquivo" icon={IconSearch} />
      <List>
        {files?.map((file, index) => (
          <Item>
            <Info>
              <strong>{file.filename}</strong>
              <div>{file.examType || 'Não informado'}</div>
            </Info>
            <Actions>
              <Action
                onClick={() => {
                  axios
                    .get(
                      makeApiUrl(
                        `/reports/${reportId}/files/${file.id}/DOWNLOAD`,
                      ),
                      {
                        responseType: 'blob',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                    .then(response => {
                      const file = new Blob([response.data], {
                        type: response.data.type,
                      });
                      const fileURL = URL.createObjectURL(file);

                      // Abre o arquivo em uma nova guia
                      window.open(fileURL);
                    })
                    .catch(error => {
                      AlertMessage({
                        message: 'Erro ao abrir arquivo',
                        type: 'danger',
                      });
                    });
                }}
              >
                <Icon name="visibility" size="small" color="black-1" />
              </Action>
              <Action
                onClick={() => {
                  axios
                    .get(
                      makeApiUrl(
                        `/reports/${reportId}/files/${file.id}/DOWNLOAD`,
                      ),
                      {
                        responseType: 'blob',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                    .then(response => {
                      saveAs(response.data, file.filename);
                    })
                    .catch(error => {
                      AlertMessage({
                        message: 'Erro ao abrir arquivo',
                        type: 'danger',
                      });
                    });
                }}
              >
                <Icon name="download" size="small" color="black-1" />
              </Action>
            </Actions>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default ListDiagnosisFiles;
