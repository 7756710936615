import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderBox = styled.div`
  /* Add your styles here */
`;

export const Title = styled.h1`
  /* Add your styles here */
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainInfo = styled.div`
  font-size: 14px;
  font-weight: 400;

  color: #222529;
`;

export const BoxInputIcon = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #dedede;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
  border-bottom-left-radius: none;
  border-top-left-radius: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubInfo = styled.div``;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  /* background-color: #f8f8f8; */
  /* flex-direction: column; */

  padding-top: 24px;
  padding-left: 72px;
  padding-right: 72px;
`;

export const TabsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FilterBox = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterIconBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;

  border: 1px solid #ccc;

  cursor: pointer;
`;

export const PopoverContentBox = styled.div`
  border: 1px solid #dedede;
  border-radius: 4px;

  cursor: pointer;
`;

interface PriorityProps {
  priority: 'HIGH' | 'NORMAL' | 'LOW' | 'EMERGENCY';
}

export const priorityColorStyle = (
  priority: 'HIGH' | 'NORMAL' | 'LOW' | 'EMERGENCY',
) => {
  switch (priority) {
    case 'HIGH':
      return css`
        svg path {
          fill: #dd0404;
        }
      `;

    case 'NORMAL':
      return css`
        svg path {
          fill: #fe632f;
        }
      `;

    case 'LOW':
      return css`
        svg path {
          fill: #458108;
        }
      `;

    default:
      return css`
        svg path {
          fill: #458108;
        }
      `;
  }
};

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ priority }) => priorityColorStyle(priority)}
`;
