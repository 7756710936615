import { ColumnDef } from '@tanstack/react-table';
import {
  Icon,
  IconButton,
  SortButton,
  Tags,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { Popover } from '~/presentation/components/UI/popover';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import { Information } from './styles';
import {
  iOnCallRequest,
  iOnCallRequestRecords,
} from '~/domain/interfaces/models/OnCallRequest';
import { History } from '~/main/routes';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

type Variant =
  | 'white'
  | 'blue'
  | 'green'
  | 'red'
  | 'purple'
  | 'pink'
  | 'orange';

export const columns_attendence: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as iOnCallRequest['records'][0]['consultant'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(data?.birthdate)}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'onCall',
    header: ({ column }) => {
      return <SortButton column={column}>Especialidade</SortButton>;
    },
    cell: ({ row }) => {
      const onCall = row.getValue(
        'onCall',
      ) as iOnCallRequest['records'][0]['onCall'];

      return <Typography variant="b4_14regular">{onCall.name}</Typography>;
    },
  },
  {
    accessorKey: 'updated',
    header: ({ column }) => (
      <SortButton column={column}>Última atualização</SortButton>
    ),
    cell: ({ row }) => {
      const updated = row.getValue(
        'updated',
      ) as iOnCallRequest['records'][0]['updated'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(updated)?.date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(updated)?.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'outcome',
    header: ({ column }) => <SortButton column={column}>Desfecho</SortButton>,
    cell: ({ row }) => {
      const outcome = row.getValue(
        'outcome',
      ) as iOnCallRequest['records'][0]['outcome'];

      const status: Record<string, string> = {
        RETURN: 'Retorno',
        TRANSFER: 'Transferência',
        DEATH: 'Óbito',
        PERMANENCE: 'Permancência',
        REFERRAL: 'Encaminhamento',
        CLINICAL_DISCHARGE: 'Alta clínica',
      };

      const statusColor: Record<string, string> = {
        DEATH: 'white',
        CLINICAL_DISCHARGE: 'green',
        REFERRAL: 'purple',
        RETURN: 'pink',
        PERMANENCE: 'blue',
      };

      return (
        <Information>
          <Tags
            variant={statusColor[outcome?.msgKey] as Variant}
            text={status[outcome?.msgKey] as string}
          />
        </Information>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      // const professional_id = getProfessionalInfo();

      // const handleGoToDetails = (id: number, edit = false) => {
      //   if (edit) {
      //     history.push(`/diagnosis/${id}`, {
      //       edit,
      //     });
      //     return;
      //   }

      //   history.push(`/diagnosis/${id}`);
      // };

      const store: iStore = storeDev.getState();
      const { role } = store.auth.selectUser;

      const contentPopover = (
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            flexDirection: 'column',
            padding: '8px 16px',
          }}
        >
          {value?.permissions?.edit && (
            <div
              style={{
                display: 'flex',
                height: 48,
                alignItems: 'center',
                gap: 8,
                cursor: 'pointer',
              }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'updatedRequestWaiting',
                  actionCancel: () => {
                    closeModal();
                  },
                  actionOk: () => {
                    closeModal();
                  },
                });
              }}
            >
              <Icon name="edit" />
              <Typography variant="b3_14medium">Editar</Typography>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 48,
              gap: 8,
              cursor: 'pointer',
            }}
          >
            <Icon name="download" />
            <Typography variant="b3_14medium">Baixar parecer médico</Typography>
          </div>
        </div>
      );

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <IconButton icon="video_call" variant="primary" size="sm" />

          <Popover content={contentPopover} originX="center" originY="bottom">
            <IconButton icon="more_vert" variant="primary" size="sm" />
          </Popover>
        </div>
      );
    },
  },
];
function actionCancel() {
  throw new Error('Function not implemented.');
}
