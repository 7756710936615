/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { IconCamPlus } from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';
import { DutyRequestDetailsCard } from '~/presentation/components/DutyRequestDetailsCard';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import PatientDutyList from '../Duty/Lists';
import { Wrapper, Box, Title, TitleText, Content } from './styles';
import ProfessionalDutyList from './List';
import { columns_waiting } from './columns/columns_waiting';
import { columns_inprogess } from './columns/columns_progess';
import { columns_clinicalopnion } from './columns/columns_reporting';
import { columns_attendence } from './columns/columns_monitoring';
import { columns_finalized } from './columns/columns_closed';
import {
  Button,
  Chips,
  Icon,
  InputSearch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
  Typography,
} from '@wisecare-tech/design-system-web';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { History } from '~/main/routes';

interface ownProps {
  onCallId: number;
}

interface Params {
  specialty: string;
  healthUnitId: string;
  healthUnitName: string;
  cnes: string;
  city: string;
}

export type Status =
  | 'WAITING'
  | 'PROGRESS'
  | 'CLOSED'
  | 'REPORTING'
  | 'MONITORING';

type TabStats = 'ALL' | 'MY_APPOINTMENTS' | undefined;

const DutyRequesterPage: React.FC<ownProps> = ({ onCallId }) => {
  const [filterStatus, setFilterStatus] = useState<Status>('WAITING');
  const [tabSelected, setTabSelected] = useState<TabStats>('ALL');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [tableData, setTableData] = useState<iOnCallRequest>(
    {} as iOnCallRequest,
  );
  const { records } = useSelector((store: iStore) => store.onCallRequest);

  const current_user = useSelector((store: iStore) => store.auth);

  const professional = getProfessionalInfo();

  const [selectedRequest, setSelectedRequest] = useState<
    iOnCallRequest['records'][0] | null
  >(null);

  const { state } = useLocation<Params>();

  const data = useSelector((store: iStore) => store.onCallRequest);
  const specialistStatus = useSelector((store: iStore) => store.onCall);

  const table_waiting = useReactTable({
    data: data.records ?? [],
    columns: columns_waiting,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_inprogress = useReactTable({
    data: data.records ?? [],
    columns: columns_inprogess,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_clinicalopnion = useReactTable({
    data: data.records ?? [],
    columns: columns_clinicalopnion,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_attendence = useReactTable({
    data: data.records ?? [],
    columns: columns_attendence,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_finalized = useReactTable({
    data: data.records ?? [],
    columns: columns_finalized,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const renderTable = {
    WAITING: table_waiting,
    PROGRESS: table_inprogress,
    REPORTING: table_clinicalopnion,
    MONITORING: table_attendence,
    CLOSED: table_finalized,
  };

  const handleFilterStatus = (
    value: Status = 'WAITING',
    tabValue: TabStats = 'ALL',
  ) => {
    makeReduxListOnCallRequest().list({
      body: {
        filters: {
          status: value,
          requester:
            tabValue === 'ALL' ? undefined : current_user.info?.user?.id,
        },
      },
    });
  };

  const info = useMemo(() => {
    const dutyRequesterInfo = records?.find(
      dutyRequester => Number(dutyRequester.id) === Number(selectedRequest?.id),
    );

    return {
      name: dutyRequesterInfo?.consultant?.fullname,
      sex: dutyRequesterInfo?.consultant.sex,
      age: dutyRequesterInfo?.consultant?.birthdate,
      priority: dutyRequesterInfo?.priority,
      specialty: dutyRequesterInfo?.onCall?.name,
      subject: dutyRequesterInfo?.description,
      docValue: dutyRequesterInfo?.consultant?.docValue,
      docType: dutyRequesterInfo?.consultant?.docType,
      requestId: dutyRequesterInfo?.id,
      healthUnitId: dutyRequesterInfo?.healthUnit?.id,
      status: dutyRequesterInfo?.status,
    };
  }, [selectedRequest, records]);

  const openModalRequestConsultation = () => {
    History.getHistory().push('/request/create');
  };

  useEffect(() => {
    makeReduxListOnCallRequest().list({
      body: {
        filters: {
          status: 'WAITING',
        },
      },
    });
  }, []);

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: '10px',
          marginBottom: 16,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <Chips
            text="Todos"
            style={{ height: 40 }}
            variant={tabSelected === 'ALL' ? 'blue' : 'white'}
            onClick={() => {
              setTabSelected('ALL');
              handleFilterStatus(filterStatus, 'ALL');
            }}
          />
          <Chips
            text="Minhas solicitações"
            style={{ height: 40 }}
            variant={tabSelected === 'MY_APPOINTMENTS' ? 'blue' : 'white'}
            onClick={() => {
              setTabSelected('MY_APPOINTMENTS');
              handleFilterStatus(filterStatus, 'MY_APPOINTMENTS');
            }}
          />
          <Title>
            <TitleText>{`${state?.cnes} - ${state?.healthUnitName}`}</TitleText>
          </Title>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
          <InputSearch placeholder="Pesquisar" style={{ width: '320px' }} />
          <Button
            // style={{ marginBottom: 24, width: '25%', height: 40 }}
            onClick={openModalRequestConsultation}
            icon="video_call"
            variant="primary"
          >
            Solicitar interconsulta
          </Button>
        </div>
      </div>

      <Wrapper>
        {/* <PatientDutyList
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          variant="PROFESSIONAL"
          healthUnitId={Number(state.healthUnitId)}
        /> */}
        <div
          style={{
            width: '100%',
            height: '640px',
            maxHeight: '640px',
          }}
        >
          <Tabs
            defaultValue="waiting"
            style={{
              border: '1px solid #dedede',
              borderRadius: 4,
              borderBottom: 'none',
            }}
          >
            <TabsList>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('WAITING', tabSelected);
                  setFilterStatus('WAITING');
                }}
                value="waiting"
              >
                Aguardando ({data?.overview?.WAITING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('PROGRESS', tabSelected);
                  setFilterStatus('PROGRESS');
                }}
                value="progress"
              >
                Em andamento ({data?.overview?.PROGRESS})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('REPORTING', tabSelected);
                  setFilterStatus('REPORTING');
                }}
                value="reporting"
              >
                Aguardando parecer ({data?.overview?.REPORTING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('MONITORING', tabSelected);
                  setFilterStatus('MONITORING');
                }}
                value="monitoring"
              >
                Acompanhamento ({data?.overview?.MONITORING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('CLOSED', tabSelected);
                  setFilterStatus('CLOSED');
                }}
                value="closed"
              >
                Finalizados ({data?.overview?.CLOSED})
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Table
            style={{
              border: '1px solid #dedede',
              overflowY: 'auto',
              height: '100px',
            }}
          >
            <TableHeader>
              {renderTable[filterStatus as Status]
                .getHeaderGroups()
                .map(headerGroup => (
                  <>
                    {headerGroup.headers.map(header => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    ))}
                  </>
                ))}
            </TableHeader>

            <TableBody
              style={{
                backgroundColor: 'white',
              }}
            >
              {renderTable[filterStatus as Status]
                .getRowModel()
                .rows.map(row => (
                  <TableRow
                    key={row.id}
                    selected={row.original?.id === selectedRequest?.id}
                  >
                    {row.getAllCells().map(cell => (
                      <TableCell
                        key={cell.id}
                        onClick={() =>
                          setSelectedRequest(
                            cell.row.original as iOnCallRequest['records'][0],
                          )
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <DutyRequestDetailsCard
          info={selectedRequest}
          selectedRequester={!!selectedRequest}
          variant="REQUESTER"
          setSelectedRequest={setSelectedRequest}
        />
      </Wrapper>
    </Box>
  );
};

export default DutyRequesterPage;
