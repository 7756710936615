import { differenceInMonths, differenceInYears } from 'date-fns';

export const calculateAgeAndMonths = (birthdate: string): string => {
  const date = new Date(birthdate);

  if (isNaN(date.getTime())) {
    return 'Idade não informada';
  }

  const today = new Date();
  const age = differenceInYears(today, date);
  const months = differenceInMonths(today, date) % 12;

  const formattedBirthdate = date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return `${formattedBirthdate} (${age} anos e ${months} meses)`;
};
