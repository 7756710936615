import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@wisecare-tech/design-system-web';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { makeReduxListExamTypesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListExamTypesDiagnosisReportFactory';
import { makeRemoteSendFilesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/SendFilesDiagnosisReportFactory';
import { UploadFiles } from '~/presentation/base/icons';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';
import { columns as tableColumns } from '../columns';
import {
  ContainerFiles,
  ContentFiles,
  Dropzone,
  FileList,
  FileSize,
  FilesFormats,
  Message,
  SectionContainer,
  SectionLabel,
} from '../styles';

interface iStatus {
  status: 'success' | 'error' | 'default';
}

export const FilesSection = ({
  handleLoading,
}: {
  handleLoading?: (loading: boolean) => void;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [dropStatus, setDropStatus] = useState<iStatus['status']>('default');
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const { diagnosisData } = useGetDiagnosisContext();
  const { disableField } = useRemoteReport();

  const form = useFormContext<EditDiagnosisReport>();

  const columns = useCallback(() => {
    return tableColumns({ disableField, form });
  }, [disableField, form]);

  const table = useReactTable({
    data: form.getValues().files ?? [],
    columns: columns(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  const onDrop = useCallback(acceptedFiles => {
    form.register('files');

    const formData = new FormData();

    acceptedFiles?.forEach((file: File) => {
      formData.append('files', file);
    });

    handleLoading?.(true);
    setDropStatus('success');

    makeRemoteSendFilesDiagnosisReport()
      .send({
        body: formData,
      })
      .then(res => {
        const files = form.getValues().files ?? [];
        setDropStatus('success');

        form.setValue('files', [...files, ...res.files]);
      })
      .catch(() => {
        setDropStatus('error');
        AlertMessage({
          type: 'danger',
          message: 'Erro ao enviar arquivo(s).',
        });
      })
      .finally(() => {
        handleLoading?.(false);
        setDropStatus('default');
      });
  }, []);

  const onDropRejected = useCallback(rejectedFiles => {
    setDropStatus('error');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'audio/mpeg',
      'audio/mpa',
      'audio/wav',
      'video/mp4',
      'video/x-m4v',
      'video/x-ms-wmv',
      'video/x-msvideo',
      'video/mpeg',
      'video/quicktime',
      'application/zip',
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.pdf',
      '.doc',
      '.dcm',
      '.docx',
      '.ppt',
      '.pptx',
      '.pps',
      '.ppsx',
      '.odt',
      '.xls',
      '.xlsx',
      '.mp3',
      '.mpa',
      '.wav',
      '.mp4',
      '.m4v',
      '.wmv',
      '.avi',
      '.mpg',
      '.zip',
    ],
    maxFiles: 90,
    multiple: true,
    validator(file) {
      if (file.size > 80000000) {
        return {
          code: 'file-too-large',
          message: 'Arquivo muito grande. O tamanho máximo é 80mb.',
        };
      }
      return null;
    },
  });

  useEffect(() => {
    makeReduxListExamTypesDiagnosisReport().listExamTypes({});
  }, []);

  useEffect(() => {
    form.register('files');
    form.setValue('files', diagnosisData?.files);
  }, [diagnosisData?.files]);

  return (
    <SectionContainer>
      <SectionLabel>Arquivos</SectionLabel>

      <ContainerFiles>
        {!disableField && (
          <Dropzone
            {...getRootProps({ refKey: 'innerRef' })}
            status={dropStatus}
          >
            <input {...getInputProps()} />
            <ContentFiles>
              <Message>
                <UploadFiles />
                <span>
                  Clique para adicionar os arquivos ou arraste-os aqui.
                </span>
              </Message>
              <FileList>
                <FileSize>
                  <p>Tamanho máximo:</p>
                  <strong>80mb por arquivo</strong>
                </FileSize>
                <FilesFormats>
                  <p>É possível adicionar os seguintes formatos: </p>
                  <strong>
                    .jpg .jpeg. .png .gif .pdf .doc .dcm .docx .ppt .pptx .pps
                    .ppsx .odt .xls .xlsx .mp3 .mpa .wav .mp4 .mov .m4v .wmv
                    .avi .mpg .zip
                  </strong>
                </FilesFormats>
              </FileList>
            </ContentFiles>
          </Dropzone>
        )}

        <Table rounded>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <>
                {headerGroup.headers.map(header => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id}>
                {row.getAllCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ContainerFiles>
    </SectionContainer>
  );
};
