import { theme } from '@wisecare-tech/design-system-web';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #f8f8f8;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Subheader = styled.header`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #fdfdfd;

  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  & > text {
    color: ${theme.colors.black[4]};
  }
`;
