import { Button, Icon, Typography } from '@wisecare-tech/design-system-web';
import React from 'react';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Container, ContainerModal, Content, Footer, Text } from './styles';

interface propsModalCancelReport {
  message: iMessage;
}

const ModalCancelReportLaudo: React.FC<propsModalCancelReport> = ({
  message,
}) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.cancelReportLaudo;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFEFEA',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="warning" color="orange-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {data.type === 'cancel'
                    ? 'Cancelar solicitação'
                    : 'Rejeitar laudo'}
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  {data.type === 'cancel'
                    ? `Você está cancelando uma solicitação de laudo enviada para um profissional ou especialidade, todas as informações serão perdidas. Tem certeza que deseja continuar?`
                    : `Você foi escolhido pelo solicitante para realizar este laudo. Se rejeitá-lo, ele ficará disponível para qualquer profissional da especialidade. Tem certeza que deseja continuar?`}
                </Typography>
              </Text>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel}>
                Cancelar
              </Button>
              <Button variant="destructive" onClick={actionOk}>
                {data.type === 'cancel'
                  ? 'Sim, quero cancelar'
                  : 'Sim, quero rejeitar'}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalCancelReportLaudo;
