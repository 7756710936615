import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadAllRequestFile as UsecaseRemoteDownloadAllOnCallRequestFile } from '~/domain/usecases/onCallRequestFile/remote';

import { RemoteDownloadAllRequestFile } from '~/data/repository/onCallRequestFile';

/**
 * send request via API.
 */
export const makeRemoteDownloadAllOnCallRequestFile =
  (): UsecaseRemoteDownloadAllOnCallRequestFile =>
    new RemoteDownloadAllRequestFile(makeApiUrl('/oncall'), makeHttpClient());
