import { SendRequestFile } from '~/domain/usecases/onCallRequestFile/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteSendRequestFile implements SendRequestFile {
  private readonly url: string;

  private readonly httpClient: HttpClient<SendRequestFile.Model>;

  constructor(url: string, httClient: HttpClient<SendRequestFile.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  send = async (
    params: SendRequestFile.Params,
  ): Promise<SendRequestFile.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.onCallId}/requests/files`,
      body: params.body,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
