import styled, { css } from 'styled-components';

import { theme } from '@wisecare-tech/design-system-web';
import { StylesConfig } from 'react-select';
import { OptionType } from '..';

interface ContainerProps {
  width: string;
}

interface SelectContainerProps {
  height: string;
}

interface MessageProps {
  error: boolean;
  display?: boolean;
}

export const Container = styled.div<ContainerProps>`
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  max-width: ${({ width }) => width};
  width: 100%;
`;

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  font-weight: lighter;
  padding-left: 16px;
  border: 1px solid #bfc4ca;
  background: #ffffff;
`;

interface iSelectLabel {
  required?: boolean;
}

export const Label = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;

export const SelectStyles = (): StylesConfig<OptionType> => ({
  control(base, props) {
    return {
      ...base,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      borderRadius: '4px',
      background: '#fff',
      paddingLeft: '8px',
      ...(props.isFocused
        ? {
            outlineColor: theme.colors.blue[7],
            outline: '2px solid',
            borderColor: 'transparent',
            boxShadow: `0 0 12px 0 ${theme.colors.blue[7]}`,
          }
        : {
            border: '1px solid #bfc4ca',
          }),
      ...(props.isDisabled && {
        background: theme.colors.white[4],
      }),
    };
  },
  dropdownIndicator: props => ({
    ...props,
    color: 'inherit',
  }),
  input: props => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (base, props) => ({
    ...base,
    color: '#444a51',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ...(props.isDisabled && {
      color: theme.colors.white[9],
    }),
  }),
  menu: props => ({
    ...props,
    zIndex: 2,
  }),
});

export const Message = styled.label<MessageProps>`
  display: ${props => (props.display ? 'flex' : 'none')};
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;
  margin-top: 8px;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;
