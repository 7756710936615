import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { History } from '~/main/routes';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import {
  Button,
  Icon,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { AlertMessage } from '../messages/AlertMessage';
import { ContainerPopover, Options } from './styles/Styled';
import { Popover } from '~/presentation/components/UI/popover';
import { makeRemoteCancelOnCallRequest } from '~/main/factories/usecases/onCallRequest/CancelOnCallRequestFactory';
import { makeRemoteJoinOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/JoinOnCallSpecialistFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeRemoteJoinOnCallRequester } from '~/main/factories/usecases/onCallRequester/JoinOnCallRequesterFactory';
interface OwnProps {
  info: iOnCallRequest['records'][0] | null;
  selectedRequester?: boolean;
  variant: 'SPECIALIST' | 'REQUESTER';
  handleReset?: () => void;
  setSelectedRequest: (requester: iOnCallRequest['records'][0] | null) => void;
}

const DetailsCardButtons: React.FC<OwnProps> = ({
  info,
  variant,
  handleReset,
  setSelectedRequest,
}) => {
  const { accepted } = useSelector((store: iStore) => store.onCall);
  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const { user } = useSelector((store: iStore) => store.auth.info);

  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  const professional = getProfessionalInfo();
  const isMyRequest =
    info?.requester?.id === professional?.id ||
    info?.specialist?.id === professional?.id;

  const cancelInterconsult = () => {
    makeReduxActiveMessage().active({
      active: 'cancelInterconsult',
      actionCancel: () => {
        closeModal();
      },
      data: {
        id: info?.id,
        onSuccess: () => {
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: professional?.id,
                status: 'WAITING',
                healthUnit: info?.healthUnit.id,
              },
            },
          });
          handleReset?.();
        },
      },
    });
  };

  const updateConsultation = () => {
    makeReduxActiveMessage().active({
      active: 'requestConsultation',
      actionCancel: () => {
        closeModal();
      },
      userId: info?.id,
      data: {
        isEditing: true,
        onSuccess: () => {
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: info?.requester.id,
                status: 'WAITING',
                healthUnit: info?.healthUnit.id,
                onCall: id,
              },
            },
          });

          makeRemoteListOnCallRequest()
            .list({
              body: {
                filters: {
                  id: Number(info?.id),
                  onCall: id,
                },
              },
            })
            .then(res => {
              setSelectedRequest(res.records[0]);
            })
            .catch(() => {
              AlertMessage({
                message: 'Erro ao buscar solicitação!',
                type: 'danger',
              });
            });
        },
      },
    });
  };

  const continueInterconsult = () => {
    const isRequester = professional?.id === info?.requester?.id;

    makeRemoteCreateAppointment()
      .create({
        org: orgId,
        orgUnit: orgUnitId,
        organizer: id,
        professional: professional?.id,
        specialty: professional?.professions?.specialties?.[0]?.id,
        healthUnit: info?.healthUnit?.id,
        date: String(new Date()),
        professionals: [
          {
            id: isRequester ? info?.specialist?.id : info?.requester?.id,
            name: isRequester
              ? info?.specialist?.fullname
              : info?.requester?.fullname,
          },
        ],
        info: {
          name: info?.consultant?.fullname,
          birthDate: info?.consultant?.birthdate,
          regType: info?.consultant?.docType,
          regValue: info?.consultant?.docValue,
          sex: info?.consultant?.sex ?? undefined,
        },
        service: 'INSTANTANEOUS',
        onCallRequest: info?.id,
      })
      .then(res => {
        makeReduxActiveMessage().active({
          active: 'instantConsultationSuccess',
          link: res.links.otherProfessionals?.[0].link,
          data: {
            professional: id,
            appointmentId: res.id,
            isRequester,
            healthUnit: info?.healthUnit,
            outcomeByRequester: isRequester,
            onCall: {
              sessionName: '',
              id: info?.onCall?.id,
              ...(isRequester
                ? { requester: professional?.id }
                : {
                    name: info?.onCall?.name,
                    specialist: professional?.id,
                  }),
            },
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao criar interconsulta!',
          type: 'danger',
        });
      });
  };

  const acceptInterconsult = () => {
    if (professional && info)
      makeRemoteAcceptOnCallSpecialist()
        .accept({
          onCallId: info?.onCall.id,
          specialistId: professional?.id,
          requestId: info?.id,
        })
        .catch(() => {
          AlertMessage({
            message: 'Erro ao aceitar solicitação!',
            type: 'danger',
          });
        });
  };

  const cancelInterconsultRequest = () => {
    if (info && professional)
      makeRemoteCancelOnCallRequest()
        .cancel({
          onCallId: info?.onCall?.id,
          requestId: info?.id,
        })
        .then(() => {
          AlertMessage({
            message: 'Solicitação cancelada com sucesso.',
            type: 'success',
          });
        })
        .catch(() => {
          AlertMessage({
            message: 'Erro ao cancelar solicitação.',
            type: 'danger',
          });
        });
  };

  const isAccepted = () => {
    return accepted?.state && accepted.request === info?.id;
  };

  // const contentPopover = (
  //   <ContainerPopover
  //     onClick={() => {
  //       makeReduxActiveMessage().active({
  //         active: 'requestConsultation',
  //         actionCancel: () => {
  //           closeModal();
  //         },
  //         userId: info?.id,
  //         data: {
  //           isEditing: true,
  //           onSuccess: () => {
  //             makeReduxListOnCallRequest().list({
  //               body: {
  //                 dataControl: {
  //                   limit: 9999,
  //                 },
  //                 filters: {
  //                   requester: info?.requester.id,
  //                   status: 'WAITING',
  //                   healthUnit: info?.healthUnit.id,
  //                   onCall: id,
  //                 },
  //               },
  //             });

  //             makeRemoteListOnCallRequest()
  //               .list({
  //                 body: {
  //                   filters: {
  //                     id: Number(info?.id),
  //                     onCall: id,
  //                   },
  //                 },
  //               })
  //               .then(res => {
  //                 setSelectedRequest(res.records[0]);
  //               })
  //               .catch(() => {
  //                 AlertMessage({
  //                   message: 'Erro ao buscar solicitação!',
  //                   type: 'danger',
  //                 });
  //               });
  //           },
  //         },
  //       });
  //     }}
  //   >
  //     <div>
  //       <div>
  //         <Icon name="edit" />
  //         Atualizar atendimento
  //       </div>
  //       <div>
  //         <Icon name="download" />
  //         Baixar parecer médico
  //       </div>
  //     </div>
  //   </ContainerPopover>
  // );

  const contentPopover = (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: '8px 16px',
      }}
    >
      {info?.permissions?.edit && (
        <div
          style={{
            display: 'flex',
            height: 48,
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            makeReduxActiveMessage().active({
              active: 'updatedRequestWaiting',
              actionCancel: () => {
                closeModal();
              },
              actionOk: () => {
                closeModal();
              },
            });
          }}
        >
          <Icon name="edit" />
          <Typography variant="b3_14medium">Editar</Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          height: 48,
          alignItems: 'center',
          gap: 8,
          cursor: 'pointer',
        }}
      >
        <Icon name="download" />
        <Typography variant="b3_14medium">Baixar parecer médico</Typography>
      </div>
    </div>
  );

  const renderButton = () => {
    const renderOptions = {
      WAITING: {
        REQUESTER: (
          <Options>
            <Button
              icon="edit"
              variant="secondary"
              style={{
                width: '161px',
                visibility: info?.permissions?.edit ? 'visible' : 'hidden',
              }}
              onClick={updateConsultation}
            >
              Editar
            </Button>

            <Button
              variant="destructive"
              icon="cancel"
              style={{
                width: '100%',
                maxWidth: '161px',
                visibility: info?.permissions?.cancel ? 'visible' : 'hidden',
              }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  data: {
                    variant: 'CANCEL',
                    message:
                      'Você está cancelando esse pedido de interconsulta. Ela será movida para “finalizados”, você poderá reenviar essa solicitação caso precisar. Tem certeza de que deseja continuar?',
                    textSecondaryButton: 'Voltar',
                    textPrimaryButton: 'Sim, quero cancelar',
                    title: 'Cancelar solicitação',
                  },
                  actionOk: () => {
                    cancelInterconsultRequest();
                  },
                });
              }}
            >
              Cancelar
            </Button>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="secondary"
              icon="signature"
              style={{
                width: '100%',
                maxWidth: '161px',
              }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  actionOk: () => {
                    closeModal();
                  },
                  data: {
                    variant: 'INFO',
                    message:
                      'Caso comece a escrever o parecer médico, essa solicitação agora ficará sob sua responsabilidade. Tem certeza de que quer continuar?',
                    title: 'Iniciar parecer médico',
                    textSecondaryButton: 'Cancelar',
                    textPrimaryButton: 'Sim, quero continuar',
                  },
                });
              }}
            >
              Parecer
            </Button>
            <Button
              icon="video_call"
              variant="primary"
              style={{ width: '100%', maxWidth: '161px' }}
              disabled={accepted?.state || isMyRequest}
              onClick={acceptInterconsult}
            >
              Interconsulta
            </Button>
          </Options>
        ),
      },
      PROGRESS: {
        REQUESTER: (
          <>
            {user?.id === info?.requester?.userId && (
              <Button
                icon="login"
                variant="primary"
                style={{ width: '100%' }}
                onClick={() => {
                  if (!info) return;

                  makeRemoteJoinOnCallRequester()
                    .join({
                      onCallId: info?.onCall?.id,
                      requesterId: info?.requester?.id,
                      requestId: info?.id,
                    })
                    .then(response => {
                      History.getHistory().push('/conf', {
                        appointmentId: response.appointmentId,
                        onCall: {
                          sessionName: response.sessionName,
                          id: info.onCall?.id,
                          name: info.onCall?.name,
                          specialist: info.specialist?.id,
                        },
                      });
                    })
                    .catch(err => {
                      console.log('>>> Error Join Specialist: ', err);
                      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                        available: false,
                      });
                    });
                }}
              >
                Entrar novamente
              </Button>
            )}
          </>
        ),
        SPECIALIST: (
          <Button
            icon="login"
            variant="primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (!info) return;

              makeRemoteJoinOnCallSpecialist()
                .join({
                  onCallId: info?.onCall?.id,
                  specialistId: info?.specialist?.id,
                  requestId: info?.id,
                })
                .then(response => {
                  History.getHistory().push('/conf', {
                    appointmentId: response.appointmentId,
                    onCall: {
                      sessionName: response.sessionName,
                      id: info.onCall?.id,
                      name: info.onCall?.name,
                      specialist: info.specialist?.id,
                    },
                  });
                })
                .catch(err => {
                  console.log('>>> Error Join Specialist: ', err);
                  makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                    available: false,
                  });
                });
            }}
          >
            Entrar novamente
          </Button>
        ),
      },
      MONITORING: {
        REQUESTER: (
          <Options>
            <Button
              variant="primary"
              icon="video_call"
              onClick={continueInterconsult}
              style={{ width: '100%' }}
              size="md"
            >
              Criar interconsulta
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="primary"
              icon="video_call"
              onClick={continueInterconsult}
              style={{ width: '100%' }}
              size="md"
            >
              Criar interconsulta
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
      },
      REPORTING: {
        REQUESTER: null,
        SPECIALIST: (
          <Button
            variant="primary"
            icon="edit"
            style={{
              width: '100%',
              maxWidth: '161px',
              visibility: info?.permissions?.edit ? 'visible' : 'hidden',
            }}
          >
            Editar parecer
          </Button>
        ),
      },
      CLOSED: {
        REQUESTER: (
          <Options>
            <Button
              variant="secondary"
              icon="refresh"
              style={{ width: '100%', maxWidth: '161px' }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  data: {
                    variant: 'INFO',
                    message:
                      'Sua solicitação foi cancelada pelo profissional e agora você está reenviando para um novo atendimento. Os dados enviados pela primeira vez ficarão o mesmo. Tem certeza que deseja continuar?',
                    textSecondaryButton: 'Cancelar',
                    textPrimaryButton: 'Sim, quero reenviar',
                    title: 'Reenviar solicitação',
                  },
                  actionOk: () => {
                    closeModal();
                  },
                });
              }}
            >
              Reenviar
            </Button>
            <Button
              variant="primary"
              icon="visibility"
              style={{ width: '100%', maxWidth: '161px' }}
              onClick={() => {
                const url = window.location.pathname;

                History.getHistory().push('/appointment/detail', {
                  appointmentId: info?.currentAppointment?.id,
                  from: url.replaceAll('/', ''),
                });
              }}
            >
              Detalhes
            </Button>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="primary"
              icon="visibility"
              style={{ width: '100%' }}
            >
              Detalhes do atendimento
            </Button>
          </Options>
        ),
      },
    };

    const statusOptions = info?.status ? renderOptions[info.status] : null;

    if (statusOptions && typeof statusOptions === 'object') {
      return (statusOptions as { [key: string]: JSX.Element })[variant] || null;
    }

    return statusOptions || null;
  };

  return <>{renderButton()}</>;
};

export default DetailsCardButtons;
