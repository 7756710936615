import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadRequestFileById as UsecaseRemoteDownloadOnCallRequestFileById } from '~/domain/usecases/onCallRequestFile/remote';

import { RemoteDownloadByIdRequestFile } from '~/data/repository/onCallRequestFile';

/**
 * send request via API.
 */
export const makeRemoteDownloadOnCallRequestFileById =
  (): UsecaseRemoteDownloadOnCallRequestFileById =>
    new RemoteDownloadByIdRequestFile(makeApiUrl('/oncall'), makeHttpClient());
