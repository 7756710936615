/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconAlertInterconsult } from '~/presentation/base/icons';
import { makeRemoteCancelOnCallRequest } from '~/main/factories/usecases/onCallRequest/CancelOnCallRequestFactory';
import { closeModal } from '~/utils/closeModal';
import { makeReduxListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import {
  ContainerModal,
  Container,
  Header,
  HeaderTitle,
  Body,
  RadioContainer,
  TextAreaForm,
  Footer,
} from './styles/StyledModalCancelInterconsult';
import { Button } from '../UI';
import { translator } from '../i18n';
import { AlertMessage } from '../messages/AlertMessage';

interface ownProps {
  message: iMessage;
}

const ModalCancelInterconsult: React.FC<ownProps> = ({ message }) => {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const { active, actionCancel, data } = message;
  const msgName = MessageOptions.cancelInterconsult;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <IconAlertInterconsult />
              <HeaderTitle>
                <div className="title">Você está cancelando um atendimento</div>
                <div className="text">Selecione abaixo o motivo:</div>
              </HeaderTitle>
            </Header>
            <Body>
              <RadioContainer>
                <input
                  type="radio"
                  name="option1"
                  id="option1"
                  value="Paciente já atendido."
                  checked={reason === 'Paciente já atendido.'}
                  onChange={e => {
                    setReason(e.target.value);
                  }}
                />
                Paciente já atendido.
              </RadioContainer>
              <RadioContainer>
                <input
                  type="radio"
                  name="option2"
                  id="option2"
                  value="Paciente teve uma melhora e não necessitou do plantão."
                  checked={
                    reason ===
                    'Paciente teve uma melhora e não necessitou do plantão.'
                  }
                  onChange={e => {
                    setReason(e.target.value);
                  }}
                />
                Paciente teve uma melhora e não necessitou do plantão.
              </RadioContainer>
              <RadioContainer>
                <input
                  type="radio"
                  name="option3"
                  id="option3"
                  checked={
                    reason === 'Paciente já foi transferido para outra unidade.'
                  }
                  value="Paciente já foi transferido para outra unidade."
                  onChange={e => {
                    setReason(e.target.value);
                  }}
                />
                Paciente já foi transferido para outra unidade.
              </RadioContainer>
              <TextAreaForm>
                <RadioContainer>
                  <input
                    type="radio"
                    name="option4"
                    id="option4"
                    checked={reason === 'Outro'}
                    value="Outro"
                    onChange={e => {
                      setReason(e.target.value);
                    }}
                  />
                  Outro
                </RadioContainer>
                <textarea
                  style={{
                    width: '100%',
                    height: '120px',
                  }}
                  placeholder="Digite aqui."
                  disabled={reason !== 'Outro'}
                  name="situation"
                  onChange={e => {
                    setOtherReason(e.target.value);
                  }}
                />
              </TextAreaForm>
            </Body>
            <Footer>
              <Button variant="secundary" size="bigger" onClick={actionCancel}>
                {translator('Voltar')}
              </Button>
              {/* <Button
                variant="primary"
                size="bigger"
                disabled={!reason}
                onClick={() => {
                  makeRemoteCancelOnCallRequest()
                    .cancel({
                      reason: reason === 'Outro' ? otherReason : reason,
                      requestId: data?.id,
                    })
                    .then(() => {
                      setReason('');
                      setOtherReason('');
                      AlertMessage({
                        message: 'Solicitação cancelada com sucesso.',
                        type: 'success',
                      });
                      data?.onSuccess?.();
                      closeModal();
                    })
                    .catch(() => {
                      AlertMessage({
                        message: 'Erro ao cancelar solicitação.',
                        type: 'danger',
                      });
                      closeModal();
                    });
                }}
              >
                {translator('Cancelar atendimento')}
              </Button> */}
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalCancelInterconsult;
