import { Typography } from '@wisecare-tech/design-system-web';
import { CreateReport } from '~/presentation/components/createReportForms';
import { Menu } from '~/presentation/components/menu';
import { Body, Container, LockScroll, Subheader } from './StyledCreateReport';

const CreateReportPage = () => {
  return (
    <Container>
      <LockScroll>
        <Menu />
        <Subheader>
          <Typography variant="b3_14medium">Solicitação de laudo</Typography>
        </Subheader>
      </LockScroll>
      <Body>
        <CreateReport />
      </Body>
    </Container>
  );
};

export default CreateReportPage;
