import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { SendRequestFile as UsecaseRemoteSendRequestFile } from '~/domain/usecases/onCallRequestFile/remote';

import { RemoteSendRequestFile } from '~/data/repository/onCallRequestFile';

/**
 * send request via API.
 */
export const makeRemoteSendRequestFile = (): UsecaseRemoteSendRequestFile =>
  new RemoteSendRequestFile(makeApiUrl('/oncall'), makeHttpClient());
